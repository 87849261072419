<template>
  <div class="wrap">
    <div class="The_header">
      <ul class="bg-fff">
        <li class="left">
          <router-link to>
            <i class="iconfont icon-fanhui" @click="$router.back(-1)"></i>
          </router-link>
        </li>
        <li>{{ t("common.title.withdrawCommission") }}</li>
      </ul>
    </div>
    <div class="main main_page">
      <div class="Rech">
        <AForm
          layout="vertical"
          :model="formModel"
          ref="formRef"
          :rules="ruleRef"
          validateTrigger="change"
          @submit.prevent="handleConfirm"
          autocomplete="off"
        >
          <div class="Rech_box">
            <ul class="ul_three">
              <h1>{{ t("profile.label.withdrawAmount") }}</h1>
              <li>
                <b class="withdrawalSymbol">{{ CURRENCY_SYMBOL }}</b>
                <AFormItem name="amount">
                  <AInput
                    type="number"
                    v-model:value="formModel.amount"
                    step="any"
                    :placeholder="t('profile.placeholder.withdrawAmount')"
                  />
                </AFormItem>
              </li>
              <li></li>
              <li>
                {{ t("profile.label.balance") }}:
                <b v-if="userBalance">{{ formatMoney(userBalance.balance) }}</b>
                <button @click="withDrawAll" type="button">
                  {{ t("common.button.withdrawAll") }}
                </button>
              </li>
            </ul>
          </div>
         
          <div class="Sys_tips c-999 text-pre-line">
            {{ t("common.text.withdrawCommission") }}
          </div>
          <div class="Sys_submit_btn withdraw pb-30 Sys_submit_btn_c">
            <button type="submit">
              {{ t("common.button.withdraw") }}
            </button>
          </div>
        </AForm>
      </div>
    </div>
    <Loading :loading="loading" />
  </div>
</template>

<script>
import {
  ref,
  reactive,
  // watch,
  onMounted,
  getCurrentInstance,
  // computed,
} from "vue";
import { message } from "ant-design-vue";
// const useForm = Form.useForm;
import { useRouter } from "vue-router";
import { formatMoney, formatPercentage } from "@/utils/formatter";
import { useI18n } from "@/lang";
import Loading from "@/components/Loading.vue";
import { CURRENCY_SYMBOL } from "@/utils/constant";
import api from "@/api";
import { userDetail } from "@/hooks/userDetail";
import { sysDataDictionaryWbObj } from "@/hooks/sysDataDictionaryWbObj";
export default {
  components: { Loading },
  setup() {
    const { proxy } = getCurrentInstance();
    const { t } = useI18n();
    const router = useRouter();
    const userBalance = ref({ balance: userDetail.value?.commission });
    const userBankcard = ref({});
    const loading = ref(false);
    
    let formModel = reactive({
      amount: null
    });

    // const expect = reactive({
    //   balance: 0,
    // });

    onMounted(async () => {
      // expect.balance = userBalance.balance
    });

    const ruleRef = reactive({
       amount: [
        {
          required: true,
          message: t("profile.placeholder.withdrawAmount"),
        },
        {
          validator: async (_rule, val) => {
            if (+val < 0.01 || +val > userBalance.value.balance) {
              return Promise.reject(t("profile.placeholder.excessBalance"));
            } else {
              if (
                ("" + val).indexOf(".") != -1 &&
                ("" + val).slice(("" + val).indexOf(".")).length >= 4
              ) {
                return Promise.reject(t("error.recharge.decimalErr"));
              }
              return Promise.resolve();
            }
          },
        },
      ],
    });
    
    

    async function handleConfirm() {
      let validateResult = await proxy.$refs["formRef"]
        .validate()
        .then((e) => {
          return e;
        })
        .catch((e) => {
          return e;
        });
      if (
        !(
          validateResult &&
          validateResult.errorFields &&
          validateResult.errorFields.length === 0
        )
      ) {
        return;
      }
      // console.log('111111111111111111111',validateResult)
      submitWithdraw(formModel);
      
    }

    async function submitWithdraw() {
      loading.value = true;
      let formToSend = {
        commission: formModel.amount,
      };

      api
        .withdrawalCommission(formToSend)
        .then((res) => {
          loading.value = false;
          if (res?.data?.success) {
            api.getUserDetails();
            message.success(t("common.message.withdrawSuccess"));
            router.replace("/profile");
          }
        })
        .catch(() => {
          loading.value = false;
          message.error(t("common.message.withdrawFailed"));
        });
    }

    function withDrawAll() {
      formModel.amount = formatMoney(userBalance.value.balance,false)
    }


    return {
      t,
      userBankcard,
      userBalance,
      formModel,
      ruleRef,
      handleConfirm,
      formatMoney,
      withDrawAll,
      loading,
      
      CURRENCY_SYMBOL,
      formatPercentage,
      sysDataDictionaryWbObj,

      
    };
  },
};
</script>

<style lang="scss">
.withdrawalSymbol{
  position: relative;
  z-index: 100;
}
.text-pre-line {
  white-space: pre-line;
}

.ant-input {
  border-bottom: none !important;
}

.ant-input:focus {
  border-bottom: none !important;
}

.ant-form-item-children input:focus {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0) !important;
}

.ant-form-vertical .ant-form-explain {
  // margin-top: -15px !important;
}

.Sys_submit_btn.withdraw {
  button {
    &[disabled] {
      background: #a0a0a0;
    }
  }
}
.t_h{
  position: relative;
  top: 32px;
  left: 0;
  
  // width: 30px;
  font-size: 3.2vw;
  font-weight: normal;
  line-height: 3.2vw;
  color: #666;
  transform: translateY(-50%);
}
.t_div{
  position: relative;
  top:4px;
  width: 60%;
  left:30%;
  z-index: 2;
}
</style>
